import { Routes, Route } from "react-router-dom";
import AddUpdate from "../cms/AddUpdate";
import Dashboard from "../cms/Dashboard";
import Login from "../cms/Login";
import BlogPage from "../pages/BlogPage";
import LandingPage from "../pages/LandingPage";
import LiteraturePage from "../pages/LiteraturePage";

function RootRoute() {
  return (
    <Routes>
      <Route index path="/" element={<LandingPage />} />
      <Route path="/literature" element={<LiteraturePage />} />
      <Route path="/blog" element={<BlogPage />} />
     {
        //<Route path="/gallery" element={<GalleryPage />} />
      }
      <Route path={"/sa-admin"} element={<Login />} />
      <Route path={"/sa-admin/dashboard"} element={<Dashboard />} />
      <Route path={"/sa-admin/addupdate"} element={<AddUpdate />} />
    </Routes>
  );
}

export default RootRoute;
